import React from "react";

export default function Portfolio() {
  return (
    <>
      <section id="banner_Pages">
        <div className="container">
          <div className="row">
            <div className="banner">
              <div className="content">
                <div className="textImg">
                  <h3 className="about_banner">Portfolio</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="portfolio_div">
        <div className="container">
          <div className="row">

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://www.niromarketing.co.uk/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/niromarketing.png"
                      alt="Niro image"
                    />
                  </div>
                  <h2 className="hover-2">Niro Marketing</h2>
                </a>
              </section>
            </div>
    
            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://step2itsol.com/surcheon/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/surcheon.png"
                      alt="surcheon image"
                    />
                  </div>
                  <h2 className="hover-2">Surcheon</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://rtsportmassage.co.uk/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/rtsportmassage.png"
                      alt="Rt Sport Massage image"
                    />
                  </div>
                  <h2 className="hover-2">Rt Sports Massage</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://www.ryse.energy/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/ryse-energy.png"
                      alt="Ryse Energy image"
                    />
                  </div>
                  <h2 className="hover-2">Ryse Energy</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://www.acre.green/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/HOMEAcregreen.png"
                      alt="Doll House image"
                    />
                  </div>
                  <h2 className="hover-2">Acregreen</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://www.transition2.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/Transition2.png"
                      alt="Transition2 image"
                    />
                  </div>
                  <h2 className="hover-2">Transition2</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://bigdbm.com" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/Bigdbm.png"
                      alt="BIGDBM image"
                    />
                  </div>
                  <h2 className="hover-2">BIGDBM</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://contactfuel.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/contactfuel.png"
                      alt="Contact image"
                    />
                  </div>
                  <h2 className="hover-2">Contact Fuel</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://imprintanalytics.io/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/imprintanalytics.png"
                      alt="Imprint image"
                    />
                  </div>
                  <h2 className="hover-2">Imprint Analytics</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://superfoodlx.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/superfoodlx.png"
                      alt="Super Food image"
                    />
                  </div>
                  <h2 className="hover-2">Super Food LX</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://bigdbm.com" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/TheDollsHouse.png"
                      alt="Doll House image"
                    />
                  </div>
                  <h2 className="hover-2">The Doll House</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://www.zeus4x4.com.au/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/Zeus4x4Accessories.png"
                      alt="Zeus 4x4Accessories image"
                    />
                  </div>
                  <h2 className="hover-2">Zeus 4x4 Accessories</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a >
                  <div className="content">
                    <img
                      src="assets/img/portfolio/drHannah.png"
                      alt="drHannah image"
                    />
                  </div>
                  <h2 className="hover-2">Dr Hannah Cullen</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://shasantechnologymi.wixsite.com/awaken-clinic1" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/awakenclinic.png"
                      alt="Awaken image"
                    />
                  </div>
                  <h2 className="hover-2">Awaken clinic</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://ciaroluce.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/ciaroluce.png"
                      alt="Ciaroluce image"
                    />
                  </div>
                  <h2 className="hover-2">Ciaro luce</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://canbury.io/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/canbury.png"
                      alt="Canbury image"
                    />
                  </div>
                  <h2 className="hover-2">Canbury</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://childcareforward.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/childcareforward.png"
                      alt="Childcareforward image"
                    />
                  </div>
                  <h2 className="hover-2">Child Care Forward</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://talesoftomorrow.studio/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/TalesofTomorrowDesignStudioforConsciousCreativity.png"
                      alt="Zeus 4x4Accessories image"
                    />
                  </div>
                  <h2 className="hover-2">Tales of Tomorrow</h2>
                </a>
              </section>
            </div>



            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://acquaresearch.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/acquaresearch.png"
                      alt="Acqua image"
                    />
                  </div>
                  <h2 className="hover-2">Acqua Research</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://bakertom.co.uk/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/bakertom.png"
                      alt="Bakertom image"
                    />
                  </div>
                  <h2 className="hover-2">Bakertom</h2>
                </a>
              </section>
            </div>

            

            

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://canteengolf.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/canteengolf.png"
                      alt="Canteengolf image"
                    />
                  </div>
                  <h2 className="hover-2">Canteen Golf</h2>
                </a>
              </section>
            </div>
           

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://kingsman-homes.co.uk/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/kingsman-homes.png"
                      alt="Kingsman image"
                    />
                  </div>
                  <h2 className="hover-2">Kingsman</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://ocaccessories.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/ocaccessories.png"
                      alt="Ocaccessories image"
                    />
                  </div>
                  <h2 className="hover-2">Oc Accessories</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://step2itsol.com/oku" target="_blank">
                  <div className="content">
                    <img src="assets/img/portfolio/oku.png" alt="Oku image" />
                  </div>
                  <h2 className="hover-2">Oku</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://renovaenviro.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/renovaenviro.png"
                      alt="Renovaenviro image"
                    />
                  </div>
                  <h2 className="hover-2">Renova Enviro</h2>
                </a>
              </section>
            </div>

            

          

           

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://step2itsol.com/tap-in" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/tap-in.png"
                      alt="Tap In image"
                    />
                  </div>
                  <h2 className="hover-2">Tap In</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://thegoodiesbagg.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/thegoodiesbagg.png"
                      alt="The Goodies image"
                    />
                  </div>
                  <h2 className="hover-2">The Goodies Bagg</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://theelevon.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/theelevon.png"
                      alt="The Elevon image"
                    />
                  </div>
                  <h2 className="hover-2">The Elevon</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://veniceweddingrentals.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/veniceweddingrentals.png"
                      alt="Venice Wedding Rentals image"
                    />
                  </div>
                  <h2 className="hover-2">Venice Wedding Rentals</h2>
                </a>
              </section>
            </div>


            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://virtualescaperooms.org/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/virtualescaperooms.png"
                      alt="Virtual Escape Rooms image"
                    />
                  </div>
                  <h2 className="hover-2">Virtual Escape Rooms</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://zenalaw.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/zenalaw.png"
                      alt="Zena Law image"
                    />
                  </div>
                  <h2 className="hover-2">Zena Law</h2>
                </a>
              </section>
            </div>


            <div className="col-sm-4">
              <section className="portfolio">
                <a href="http://integratedadtech.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/integratedadtech.png"
                      alt="integratedadtech image"
                    />
                  </div>
                  <h2 className="hover-2">Integrated Ad Tech</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://ahmedfood.com.pk/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/AhmedFoods.png"
                      alt="AhmedFoods image"
                    />
                  </div>
                  <h2 className="hover-2">Ahmed Food</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://awci.org.au/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/Home-AssociationofWalls&CeilingIndustries.png"
                      alt="Home-AssociationofWalls&CeilingIndustries image"
                    />
                  </div>
                  <h2 className="hover-2">
                    Home-Association of Walls & Ceiling Industries
                  </h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://buenavistacandles.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/BuenaVistaCandleCompany.png"
                      alt="BuenaVistaCandleCompany image"
                    />
                  </div>
                  <h2 className="hover-2">Buena Vista Candle Company</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://xaitoken.com/" target="_blank">
                  <div className="content">
                    <img src="assets/img/portfolio/XAI.png" alt="XAI image" />
                  </div>
                  <h2 className="hover-2">XAI</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://aster.edu.pk/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/TheAsterSchool.png"
                      alt="TheAsterSchool image"
                    />
                  </div>
                  <h2 className="hover-2">The Aster School</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://step2itsol.com/lead-success/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/leadsuccess.png"
                      alt="leadsuccess image"
                    />
                  </div>
                  <h2 className="hover-2">Lead Success</h2>
                </a>
              </section>
            </div>

            

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://we-energize.de/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/weEnergize.png"
                      alt="We Energize image"
                    />
                  </div>
                  <h2 className="hover-2">We Energize</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://www.trajital.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/Trajital.png"
                      alt="Doll House image"
                    />
                  </div>
                  <h2 className="hover-2">Trajital</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://adoptacause.wixsite.com/adoptacause-2" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/HomeAdoptaCause.png"
                      alt="Zeus 4x4Accessories image"
                    />
                  </div>
                  <h2 className="hover-2">Adopt A Cause</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <a href="https://techysales.com/" target="_blank">
                  <div className="content">
                    <img
                      src="assets/img/portfolio/B2BLeadGenerationForDataAndAnalyticsProvidersTechysales.png"
                      alt="leadsuccess image"
                    />
                  </div>
                  <h2 className="hover-2">TechySales</h2>
                </a>
              </section>
            </div>
            

            

            <div className="col-sm-4">
              <section className="portfolio">
                <a >
                  <div className="content">
                    <img
                      src="assets/img/portfolio/womengamers.jpg"
                      alt="women gamers image"
                    />
                  </div>
                  <h2 className="hover-2">Women Gamers</h2>
                </a>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <div className="content">
                  <img
                    src="assets/img/portfolio/Logo1.png"
                    alt="logo image"
                  />
                </div>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <div className="content">
                  <img
                    src="assets/img/portfolio/Logo2.png"
                    alt="logo image"
                  />
                </div>
              </section>
            </div>

            <div className="col-sm-4">
              <section className="portfolio">
                <div className="content">
                  <img
                    src="assets/img/portfolio/Logo3.png"
                    alt="logo image"
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
