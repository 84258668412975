import React, { useEffect } from "react";

const ClutchWidget = () => {
  return (
    <div
      className="clutch-widget"
      data-url="https://widget.clutch.co"
      data-widget-type="1"
      data-height="40"
      data-nofollow="true"
      data-expandifr="true"
      data-scale="100"
      data-clutchcompany-id="2379618"
    ></div>

    // <div
    //   className="clutch-widget"
    //   data-url="https://widget.clutch.co"
    //   data-widget-type="12"
    //   data-height="375"
    //   data-nofollow="true"
    //   data-expandifr="true"
    //   data-scale="100"
    //   data-reviews="305289,304997,304638"
    //   data-clutchcompany-id="2379618"
    // ></div>

    // <div
    //   className="clutch-widget"
    //   data-url="https://widget.clutch.co"
    //   data-widget-type="4"
    //   data-height="auto"
    //   data-nofollow="true"
    //   data-expandifr="true"
    //   data-scale="100"
    //   data-reviews="305289,304997,304638"
    //   data-clutchcompany-id="2379618"
    // ></div>
  );
};

export default ClutchWidget;
