import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { FaArrowDown } from "react-icons/fa";
import { Link } from "react-scroll";
import ClutchWidget from './ClutchWidget'; 

export default function Home() {

  return (
    <>
      <section id="MainBanner">
        <div className="container">
          <div className="row">
            <div className="content">
              <div className="textImg">
                <h1>
                  <span>Innovative</span> IT Solutions <br />
                  For A Changing <span>World</span>
                </h1>
                <p>
                  Step2 IT Solutions is a modern Technology Oriented
                  Organization. <br />
                  We offer all the Solutions & Tools you need.
                </p>
              </div>

              <div className="btnlinks">
                <ul>
                  <li className="baBtnTuch">
                    <a className="btnTouch" href="/contact">
                      Get In Touch
                    </a>
                  </li>
                  <li className="baBtnSrvc">
                    <Link className="btnServi" to="services">
                      Our Services
                      <FaArrowDown />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
      <ClutchWidget />
      </section>



      <section id="who_we_are">

        <div className="container">
          <div className="row ">
            <div className="col-sm-4">
              <img
                src="assets/img/background/homePage.png"
                className="img-fluid"
              />
            </div>

            <div className="col-sm-8">
              <h2 className="headings">
                WHO WE ARE
                <img src="assets/img/step.png" className="stepimg" />
              </h2>
              <h3>
                We are a professional team of developers and web designers with
                vast experience.
              </h3>
              <p>
                Looking for a reliable partner? Pay attention to our Work. We
                have significant experience working with IT projects of any
                complexity. The results of our work will pleasantly surprise
                you. Our main activity is the creation of creative sites of
                varying complexity. From simple landing pages and blogs to
                complex stores and corporate portals.
              </p>
              <div className="btnlinks4">
                <ul>
                  <li className="baBtnTuch">
                    <a className="btnTouch" href="/about">
                      Read more
                    </a>
                  </li>

                  {/* <li><Link to="/portfolio"  >View More</Link></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <h2 className="headings">
                Vertical
                <img src="assets/img/step.png" className="stepimg" />
              </h2>
              <p>
                Step2 IT Solutions is a Top Web Design, Development, and
                Software Solutions services provider based in Virginia, Qatar &
                Pakistan. In addition to the core development services, we are
                also experts in Graphic Designing, SEO, Social Media Marketing
                Services, and PPC. Whereas in Web Development our focus is on
                WordPress, Shopify, e-commerce solutions and Custom Build Web
                Applications along with Mobile Apps Development. Moreover
                Corporate Branding and Content Writing are also part of our
                services portfolio. Hence complete Digital services can be found
                at a single station.
              </p>
              <div className="btnlinks4">
                <ul>
                  <li className="baBtnTuch">
                    <a href="/services" className="btnTouch">
                      View more
                    </a>
                  </li>

                  {/* <li><Link to="/portfolio"  >View More</Link></li> */}
                </ul>
              </div>
            </div>
            <div className="col-sm-8">
              <div id="accordion">
                <div className="card">
                  <div
                    className="card-header"
                    data-bs-toggle="collapse"
                    href="#ecommerce"
                  >
                    <h3 className="btn">Ecommerce Development</h3>
                  </div>
                  <div
                    id="ecommerce"
                    className="collapse show"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      {/* <h3>Ecommerce Development</h3> */}
                      We create eCommerce websites for different
                      projects/businesses by following the best project CRO
                      methodologies. We are specialized in high CTA, CRO, and
                      landing pages for great ROI.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div
                    className="card-header"
                    data-bs-toggle="collapse"
                    href="#digital"
                  >
                    <h3 className="collapsed btn">Digital Marketing</h3>
                  </div>
                  <div
                    id="digital"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      {/* <h3>Digital Marketing</h3> */}
                      We provide cross-platform digital media marketing, SEO,
                      and paid marketing, that can increase CRO using various
                      social and digital media platforms.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div
                    className="card-header"
                    data-bs-toggle="collapse"
                    href="#seo"
                  >
                    <h3 className="collapsed btn">SEO</h3>
                  </div>
                  <div
                    id="seo"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      {/* <h1>SEO</h1> */}
                      SEO is a great marketing effort for getting more business
                      than other channels. If you are consistent in your website
                      SEO you can rule the competitors.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div
                    className="card-header"
                    data-bs-toggle="collapse"
                    href="#software"
                  >
                    <h3 className="collapsed btn">Software Development</h3>
                  </div>
                  <div
                    id="software"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      {/* <h1>Software Development</h1> */}
                      We offer low-cost cloud-based software to help you in
                      business growth. We can customize the software according
                      to your specific need. Bespoke software is our specialty.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div
                    className="card-header"
                    data-bs-toggle="collapse"
                    href="#web"
                  >
                    <h3 className="collapsed btn">Web Development</h3>
                  </div>
                  <div
                    id="web"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      {/* <h1>Web Development</h1> */}
                      Our Web Development service covers Static Websites,
                      Dynamic websites, e-Commerce Sites, and Online Portals.
                      Hence anything under a cloud-based solution can be catered
                      to in the domain of Web Development. We are well
                      experienced in providing Web Solutions.
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div
                    className="card-header"
                    data-bs-toggle="collapse"
                    href="#design"
                  >
                    <h3 className="collapsed btn">Creative Design</h3>
                  </div>
                  <div
                    id="design"
                    className="collapse"
                    data-bs-parent="#accordion"
                  >
                    <div className="card-body">
                      {/* <h1>Creative Design</h1> */}
                      Our creative team designs every aspect of the world
                      ranging from print ads to product packages.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

     
      <div className="paralax_client">
        <section id="clients">
          <div className="container">
            <h2 className="headingss">
              CLIENTS <img src="assets/img/step.png" className="stepimg" />
            </h2>

            <section className="customer-logos slider">
              <div className="slide">
                <img src="assets/img/clients/ahmed.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/awci.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/Asset10.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/bananacrystal.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/awaken.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/buena.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/bakerstom.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/canbury.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/bigdbm.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/childcare.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/canteen.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/Ciaro.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/zeus.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/client_1-10.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/contactfuel.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/Dell.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/eleve1112.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/Gooodies.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/imprint.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/hannah.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/kingsmen.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/herbaneet.png" />
              </div>
            </section>
          </div>
        </section>

        <section id="clients2">
          <div className="container">
            <section className="customer-logos slider">
              <div className="slide">
                <img src="assets/img/clients/integratedAdtech.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/nasra.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/Metro.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/nibd.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/modest.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/NRO.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/Oc.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/OKU.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/ryseEnergy.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/renova.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/techysale.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/RT.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/thredz.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/starschool.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/uriabhaur.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/tapin.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/wenergize.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/theElevon.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/zena.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/Venice.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/techysale.png" />
              </div>
              <div className="slide">
                <img src="assets/img/clients/virsualescape.png" />
              </div>
            </section>
          </div>
        </section>
      </div>

      <section id="our_works">
        <div className="container">
          <h2 className="headings">
            Our Work <img src="assets/img/step.png" className="stepimg" />
          </h2>
        </div>
        <div className="our_work horizontal ">
          <section className="dark_bg works SpacSePortfolio">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <a href="https://www.niromarketing.co.uk/" target="_blank">
                    <img
                      src="assets/img/portfolio/niromarketing.png"
                      className="img-fluid"
                    />
                  </a>
                </div>
                <div className="col-sm-6 TextColPort imPorAdJ">
                  <a href="https://www.niromarketing.co.uk/" target="_blank">
                    <img src="assets/img/background/NRO.png" className="" />
                  </a>
                  <p></p>
                  
                </div>
              </div>
            </div>
          </section>

          <section className="light_bg works SpacSePortfolio order-reverse">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-sm-6  TextColPort2 imPorAdJ">
                  <a href="https://bigdbm.com" target="_blank">
                    <img src="assets/img/background/bigdbm.png" className="" />
                  </a>
                  <p></p>
                 
                </div>
                <div className="col-sm-6">
                  <a href="https://bigdbm.com" target="_blank">
                    <img
                      src="assets/img/portfolio/Bigdbm.png"
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="dark_bg works SpacSePortfolio">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <a href="https://www.ryse.energy/" target="_blank">
                    <img
                      src="assets/img/portfolio/ryse-energy.png"
                      className="img-fluid"
                    />
                  </a>
                </div>
                <div className="col-sm-6 imPorAdJ TextColPort">
                  <a href="https://www.ryse.energy/" target="_blank">
                    <img src="assets/img/background/ryse.png" className="" />
                  </a>
                 
                </div>
              </div>
            </div>
          </section>

          <section className="light_bg works SpacSePortfolio order-reverse">
            <div className="container">
              <div className="row align-items-center TextColPort">
                <div className="col-sm-6 imPorAdJ">
                  <a href="https://rtsportmassage.co.uk/" target="_blank">
                    <img src="assets/img/background/RT.png" className="" />
                  </a>
                  
                </div>
                <div className="col-sm-6">
                  <a href="https://rtsportmassage.co.uk/" target="_blank">
                    <img
                      src="assets/img/portfolio/rtsportmassage.png"
                      className="img-fluid"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>

          <div className="btnlinks3">
            <ul>
              <li className="baBtnTuch">
                <a className="btnTouch" href="/portfolio">
                  View more
                </a>
              </li>

              {/* <li><Link to="/portfolio"  >View More</Link></li> */}
            </ul>
          </div>
        </div>
      </section>

      <section id="people_say">
        <div className="container">
          <h2 className="headingss">
            WHAT PEOPLE SAY ABOUT US
            <img src="assets/img/step.png" className="stepimg" />
          </h2>
          <div className="row">
            <div className="col-md-12">
              <div id="testimonial-slider" className="owl-carousel">
                <div className="testimonial">
                  <p className="description">
                  <h3>
                      <img src="assets/img/step.png" className="stepimg" />
                      Yassin Maher, Codyexa
                    </h3>
                    <div class="rating-stars">
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                    </div>
                    Step2 delivered good work on this project and I enjoyed
                    working with him. His communication was top-notch, he met
                    all deadlines, and his skills were reasonably strong.
                    
                  </p>
                </div>
                <div className="testimonial">
                  <p className="description">
                  <h3>
                      <img src="assets/img/step.png" className="stepimg" />
                      John Dang
                    </h3>
                    <div class="rating-stars">
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                    </div>
                    It’s been great in working with you on the project. Great
                    communications as well as an understanding of requirements
                    and technology. Will definitely keep in touch for future
                    project considerations. Highly recommended.
                    
                  </p>
                </div>
                <div className="testimonial">
                  <p className="description">
                    <h3>
                      <img src="assets/img/step.png" className="stepimg" />
                      Lucian Muresan, S.C. Cloud D8A NGIN S.R.L
                    </h3>
                    <div class="rating-stars">
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                      <i class="fas fa-star"></i>
                    </div>
                    Step2 has a super nice and friendly staff that used his
                    skills in helping us gain the perfect results for a very
                    decent price. The company website, which they made from
                    scratch, ended up exactly as we needed even if a lot of
                    corrections had to be made to make it work on both desktop
                    and mobile devices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      
    </>
  );
}
