import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";

const Footer=()=> {
  return (
    <>
    

    <section id="portfolio">
        <div className="container">
            <div className="row">
                <h2 className='headings text-black'>LET’S BOOST YOUR BUSINESS WITH <br/>THE LATEST TECHNOLGY</h2>
                <div className="btnlinks4 btnFooter">
                    <ul>
                      <li className="baBtnTuch">
                        <a className="btnTouch" href="/#/contact">Contact US</a>
                      </li>
                    </ul>
                  </div>
                
            </div>
        </div>
    </section>

    <section id="footer">
      <div className="container">
            <footer className="py-5">
              <div className="row">
                <div>

                </div>
              <div className="col-md-6 col-lg-6 col-xl-4">
              <a href="/"><img src="assets/img/logo/step2_logo_white.png" width="140px"/></a>
              <p className='footer-service'>We provide 24/7 Support service to our valued clients all over the Globe. Our support services include overall maintenance of the website, data entry, content writing, chat support and other services as per the client’s requirement. </p>
              <div className='footer-social'>
              <ul>
                <div>
                <li className="social"><Link to="https://www.facebook.com/step2itsol" className="nav-link"><span className="fab fa-facebook"></span></Link></li>
                </div>

                <div>
                <li className="social"><Link to="https://www.linkedin.com/company/step2itsolutions/" className="nav-link"><span className="fab fa-linkedin"></span></Link></li>
                </div>
              
               
                
              </ul>
              </div>
                  
              </div>
              <div className="col-md-6 col-lg-6 col-xl-4 footer-spacing">
              <h5>Services</h5>
                  <ul className="nav flex-column">
                    <li className="nav-item"><a href="/#/services" className="nav-link p-0 text-body-secondary">Creative Design</a></li>
                    <li className="nav-item"><a href="/#/services" className="nav-link p-0 text-body-secondary">Web Development</a></li>
                    <li className="nav-item"><a href="/#/services" className="nav-link p-0 text-body-secondary">Software Development</a></li>
                    <li className="nav-item"><a href="/#/services" className="nav-link p-0 text-body-secondary">SEO</a></li>
                    <li className="nav-item"><a href="/#/services" className="nav-link p-0 text-body-secondary">Digital Marketing</a></li>
                    <li className="nav-item"><a href="/#/services" className="nav-link p-0 text-body-secondary">Ecommerce Development</a></li>
                  </ul>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-4 footer-spacing detFter">
              <h5>Contact</h5>
              
                    <p className="adreFter"><strong>Step2 Pakistan:</strong></p>
                    <p><i className="fas fa-map-marker-alt"></i> A-1/3 Block 2 Gulshan-e-Iqbal, Karachi.</p>
                    <p><i className="fas fa-phone-alt"></i> +92 333 030 22 66</p>

                    <p className="adreFter"><strong>Step2 USA:</strong></p>
                    <p><i className="fas fa-map-marker-alt"></i> 704 Tonquin Place Northeast Leesburg, VA 20176.</p>
                    <p><i className="fas fa-phone-alt"></i> +1 415 799 9875</p>
                    <p><i className="fas fa-envelope"></i> <Link to="mailto:info@step2itsol.com">info@step2itsol.com</Link></p>
              </div>
                
              </div> 
          
              
            </footer>
            
          </div>
          
          <footer className='footer-tab'>
          <div className="container ">
                <div className="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 ">
                  <p>Copyright &copy; 2024. All rights reserved.</p>
                
                </div>
              </div>
          </footer>
          
          
    </section>
    </>
  );
}

export default Footer;
