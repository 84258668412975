import React from "react";
import { BrowserRouter as Router,  Routes, Route, Link, useRouteMatch, useParams } from "react-router-dom";

import Header from './Components/Header';
import Home from './Components/Home';
import About from './Components/About';
import Services from './Components/Services';
import Portfolio from './Components/Portfolio';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import ScrollToTop from "./Components/ScrollToTop";
import NotFound from "./Components/NotFound";


function App() {
  
  return (
    <>
    <Router>
        <ScrollToTop/>
        <Header/>
        <Routes>
          
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/portfolio" element={<Portfolio />} />
            <Route exact path="/contact" element={<Contact />} />

            <Route path="*" element={<NotFound/>} />
        </Routes>
       
        <Footer />
    </Router>
    </>
  );
}

export default App;
