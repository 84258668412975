import React from "react";
import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <>
      <section id="banner_Pages">
        <div className="container">
          <div className="row">
            <div className="banner">
              <div className="content">
                <div className="textImg">
                  <h3 className="about_banner">Contact Us</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='id="services"'></section>

      <section className="contact-us-section">
        <div className="container">
          <div className="main-contact">
            <div className="contact-container">
              <h4 className="contact-heading-title">
                Alright! Let’s Get to Work
              </h4>
            </div>

            <div className="para">
             Give us a <a href="tel:+92 333 0302266" className="cntc-cntnt">Call</a> Or <a href="mailto:info@step2itsol.com" className="cntc-cntnt">Email</a> to start your journey towards the growth.
            </div>
       
           
            <div className="contact-down-container">
              <div>
                The Step 2 IT Solutions is committed to excellence and vows to
                make your experience memorable and remarkable. Let us help you
                in standing out and reaching the maximum of your potential.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-us">
        <div className="Aboutcontainer">
          <div className="Aboutcontent">
            <div className="left-side">



            <div className="col footer-spacing detFter contact_details">
            
              
                    <p className="adreFter"><strong>Step2 Pakistan:</strong></p>
                    <p><i className="fas fa-map-marker-alt"></i> A-1/3 Block 2 Gulshan-e-Iqbal, Karachi.</p>
                    <p><i className="fas fa-phone-alt"></i> +92 333 030 22 66</p>
                    

                    <p className="adreFter"><strong>Step2 USA:</strong></p>
                    <p><i className="fas fa-map-marker-alt"></i> 704 Tonquin Place Northeast Leesburg, VA 20176.</p>
                    <p><i className="fas fa-phone-alt"></i> +1 415 799 9875</p>
                    <p><i className="fas fa-envelope"></i> <Link to="mailto:info@step2itsol.com">info@step2itsol.com</Link></p>
              </div>



              
              

            </div>
            <div className="right-side">
              <iframe src="https://www.google.com/maps/d/embed?mid=19w2M-u8EV8u9IopEAQ2l3lmL6nZVwtE&amp;ehbc=2E312F" width="800" height="480"></iframe>
            </div>
          </div>


          


        </div>
      </section>
    </>
  );
}
