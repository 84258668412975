import React from 'react'

export default function About() {
  return (
    <>
    <section id="banner_Pages">
      <div className="container">
          <div className="row">
            <div className="banner">
              <div className="content">
                <div className="textImg">
                    <h3 className='about_banner'>
                        About Us
                    </h3>
                </div>
                
              </div>
            </div>
            
          </div>
      </div>
    </section>

    <section id="services">
        <div className="container">
            <div className="row">
                
                <div className="col-sm-6">
                    <h2 className='about_section_heading'>
                        FUTURISTIC DIGITAL SOLUTION
                    </h2>
                    <p>Step 2 IT Solutions is a reliable and trustworthy software and web development warehouse serving clients all around the world.</p>
                    <p>Step 2 IT Solutions is a business-focused technology solutions company that has been helping businesses around the world to add value to their business and achieve the competitive edge needed in this technologically advanced market.</p>
                    <p>Our dedicated and experienced technical team is well aware of all the aspects needed to drive your business to the next level. As for us, it’s the “Results” that matter, and using our team’s unique skills we can make it happen. We have happy and very satisfied clients all over the world.</p>
                    <p>We truly understand the added value a business can develop from a website, app, or in-house software, which we can develop for your business-specific needs and also help to market in the focused market.</p>
                    <p>So let us serve you by bringing new business opportunities to your company. Like our Motto “Step ahead to make a difference” at its best.</p>
                </div>
                <div className="col-sm-6 abutImg-reverse">
                        <img src="assets/img/background/aboutPage.png" className="img-fluid"/>
                </div>
            </div>
        </div>
    </section>


    </>
  )
}
