import React from "react";
import { Link } from 'react-router-dom';


const NotFound = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100">
      <h1 className="display-4 mb-4">404 - Not Found</h1>
      <p className="lead mb-4">The page you are looking for does not exist.</p>
      <div className="btnlinks4">
                <ul>
                  <li className="baBtnTuch">
                    <a className="btnTouch" href="/">
                    Go back to Home
                    </a>
                  </li>

                  {/* <li><Link to="/portfolio"  >View More</Link></li> */}
                </ul>
              </div>
    </div>
  );
};

export default NotFound;
