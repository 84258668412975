import React from 'react';
import { Link } from 'react-scroll';

export default function Services() {
  return (
    <>
       <section id="banner_Pages">
      <div className="container">
          <div className="row">
            <div className="banner">
              <div className="content">
                <div className="textImg">
                    <h3 className='about_banner'>
                        Services
                    </h3>
                </div>
                
              </div>
            </div>
            
          </div>
      </div>
    </section>

    <section id="services_section">
      <div className="container">
          <div className="row"> 
                  <div className="col-md-12 col-lg-6 col-xl-4">
                    <Link to="Creative-Design-section" smooth={true} duration={500}>
                      {/* Add onClick if you want to add additional logic when clicking */}
                      <div className='srvcSec'>
                        <img height="180px" width="340px" src='/assets/img/services/creative-design.jpg' alt="Creative Design" />
                        <h4>Creative Design</h4>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-12 col-lg-6 col-xl-4">
                    <Link to="Web-Development-section" smooth={true} duration={500}>
                      {/* Add onClick if you want to add additional logic when clicking */}
                      <div className='srvcSec'>
                        <img height="180px" width="340px" src='/assets/img/services/web development.jpg' alt="web Development" />
                        <h4>Web Development</h4>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-12 col-lg-6 col-xl-4">
                    <Link to="Software-Development-section" smooth={true} duration={500}>
                      {/* Add onClick if you want to add additional logic when clicking */}
                      <div className='srvcSec'>
                        <img height="180px" width="340px" src='/assets/img/services/softwear.jpg' alt="Software Development" />
                        <h4>Software Development</h4>
                      </div>
                    </Link>
                  </div>    

                  <div className="col-md-12 col-lg-6 col-xl-4">
                    <Link to="Ecommerce-section" smooth={true} duration={500}>
                      {/* Add onClick if you want to add additional logic when clicking */}
                      <div className='srvcSec'>
                      <img height="180px" width="340px" src='/assets/img/services/Ecommerce.jpg' alt="Ecommerce Development" />
                        <h4>Ecommerce Development</h4>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-12 col-lg-6 col-xl-4">
                    <Link to="Digital-Marketing-section" smooth={true} duration={500}>
                      {/* Add onClick if you want to add additional logic when clicking */}
                      <div className='srvcSec'>
                        <img height="180px" width="340px" src='/assets/img/services/Digital.jpg' alt="Digital Marketing" />
                        <h4>Digital Marketing</h4>
                      </div>
                    </Link>
                  </div>

                  <div className="col-md-12 col-lg-6 col-xl-4">
                    <Link to="seo-section" smooth={true} duration={500}>
                      {/* Add onClick if you want to add additional logic when clicking */}
                      <div className='srvcSec'>
                        <img height="180px" width="340px" src='/assets/img/services/seo.jpg' alt="seo" />
                        <h4>SEO</h4>
                      </div>
                    </Link>
                  </div>
          </div>
      </div>
            
   
    </section>

    <section id="services">
    <div className='container '>
         <div className='row left-border' id="Creative-Design-section">
              <div className='col-md-3 hedBoxSer'>
              <h3 className='service-sectition-title'>Creative <br/><span>Design</span>
              
              </h3>

              </div>
            
               <div  className='col-md-9'>
               
               <span>Our creative team designs every aspect of the world ranging from print ads to product packages.</span>

               </div>
               
            

         </div>

         <div className='row section-spacing right-border' id="Web-Development-section">
              
            
               <div  className='col-md-9 hedBoxSer'>
               
               <span>Our Web Development service covers Static Websites, Dynamic websites, e-Commerce Sites, and Online Portals. Hence anything under a cloud-based solution can be catered to in the domain of Web Development. We are well experienced in providing Web Solutions.</span>

               </div>
               <div className='col-md-3'>
               <h3 className='service-sectition-title'>Web <br/> <span>Development</span></h3>

              </div>
            

         </div>

         <div className='row section-spacing left-border' id="Software-Development-section">
              <div className='col-md-3 '>
              <h3 className='service-sectition-title'>Software <br/> <span>Development</span></h3>

              </div>
            
               <div  className='col-md-9'>
               
               <span>We offer low-cost cloud-based software to help you in business growth. We can customize the software according to your specific need. Bespoke software is our specialty.</span>

               </div>
               
            

         </div>

         <div className='row section-spacing right-border' id="Ecommerce-section">
              
            
               <div  className='col-md-9'>
               
               <span>We create eCommerce websites for different projects/businesses by following the best project CRO methodologies. We are specialized in high CTA, CRO, and landing pages for great ROI.</span>

               </div>
               <div className='col-md-3'>
               <h3 className='service-sectition-title'>Ecommerce <span>Development</span></h3>

              </div>
            

         </div>

         <div className='row section-spacing left-border' id="Digital-Marketing-section">
              <div className='col-md-3'>
              <h3 className='service-sectition-title'>Digital <br/><span> Marketing</span> </h3>

              </div>
            
               <div  className='col-md-9'>
               
               <span>We provide cross-platform digital media marketing, SEO, and paid marketing, that can increase CRO using various social and digital media platforms.</span>

               </div>
               
            

         </div>

         <div className='row section-spacing right-border' id="seo-section">
              
            
               <div  className='col-md-9'>
               
               <span>SEO is a great marketing effort for getting more business than other channels. If you are consistent in your website SEO you can rule the competitors.</span>

               </div>
               <div className='col-md-3'>
               <h3 className='service-sectition-title'><span>SEO</span></h3>

              </div>
            

         </div>

       </div>

        {/* <div className="container">
            <div className="row">
                
                <div className="col-sm-6">
                    <h2 className='about_section_heading'>
                        Create Spark with Web Design Solutions to Open New Opportunities.
                    </h2>
                    <p>
                        Step2 IT Solutions is a Top Web Design,  Development, and Software Solutions services provider based in Karachi. In addition to the core development services, we are also experts in Graphic Designing, SEO, Social Media Marketing Services, and PPC. Whereas in Web Development our focus is on WordPress, Shopify, e-commerce solutions and Custom Build Web Applications along with Mobile Apps Development. Moreover Corporate Branding and Content Writing are also part of our services portfolio. Hence complete Digital services can be found at a single station.
                    </p>
                </div>
                <div className="col-sm-6" >
                        <img src="assets/img/background/servicePage.png" className="img-fluid"/>
                </div>
            </div>
        </div> */}
    </section>

    
    </>
  )
}
