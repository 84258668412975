import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Header() {
  const [activeLink, setActiveLink] = useState("Home");
  const location = useLocation();

  const handleClick = (link) => {
    setActiveLink(link);
  };

  // Extract the pathname from the location
  const currentPath = location.pathname;

  return (
    <>
      <div className="site-mobile-menu site-navbar-target">
        <div className="site-mobile-menu-header">
          <div className="site-mobile-menu-close mt-3">
            <span className="icon-close2 js-menu-toggle"></span>
          </div>
        </div>
        <div className="site-mobile-menu-body"></div>
      </div>

      <header className="site-navbar js-sticky-header site-navbar-target" role="banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 col-xl-2">
              <h1 className="mb-0 site-logo">
                <Link to="/">
                  <img src="assets/img/logo/step2_logo_white.png" width="140px" alt="Logo" />
                </Link>
              </h1>
            </div>

            <div className="col-12 col-md-10 d-none d-xl-block">
              <nav className="site-navigation position-relative text-end" role="navigation">
                <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                  <li className={currentPath === "/" ? "active" : ""}>
                    <a href="/" onClick={() => handleClick("Home")} className="nav-link">Home</a>
                  </li>
                  <li className={currentPath === "/about" ? "active" : ""}>
                    <Link to="/about" onClick={() => handleClick("About")} className="nav-link">About Us</Link>
                  </li>
                  <li className={currentPath === "/services" ? "active" : ""}>
                    <Link to="/services" onClick={() => handleClick("Services")} className="nav-link">Services</Link>
                  </li>
                  <li className={currentPath === "/portfolio" ? "active" : ""}>
                    <Link to="/portfolio" onClick={() => handleClick("Portfolio")} className="nav-link">Portfolio</Link>
                  </li>
                  <li className={currentPath === "/contact" ? "active" : ""}>
                    <Link to="/contact" onClick={() => handleClick("Contact")} className="nav-link">Contact Us</Link>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="col-6 d-inline-block d-xl-none ml-md-0 py-3" style={{ top: "3px", position: "relative" }}>
              <Link to="#" className="site-menu-toggle js-menu-toggle float-end">
                <span className="icon-menu h3"></span>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
